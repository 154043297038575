// option
var dimCk = true;
var parentsUtag = "";

function joinStamp(dataValue) {
	
	var instantOptions = {};
	instantOptions.data = dataValue.data;
	instantOptions.callbackSuccess = dataValue.callbackSuccess;
    instantOptions.callbackError = dataValue.callbackError;
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }
	
	$.ajax({
		type 	 : "POST",
		dataType : "json",
		async	 : true,
		url		 : '/public/promotion/event/insertStamp.json', 		
		data	 : instantOptions.data,
		beforeSend: function (request) {
            var csrfToken = $("meta[name='_csrf']").attr("content");
            var csrfName = $("meta[name='_csrf_header']").attr("content");
            request.setRequestHeader(csrfName, csrfToken);
        },
		success  : function(data) {
			if (instantOptions.callbackSuccess) {
				instantOptions.callbackSuccess(data);
			}
		},
		error: function( xhr, status, err ) {
			if (instantOptions.callbackError) {
				instantOptions.callbackError(xhr, status, err);
			} else {
				var data = $.parseJSON(xhr.responseText)
				alert(data.message);
			}
        }
	}).done(function () {
        dimCk = true;
    });
}

function paramSet(pA, pB) {
	
	if (isNaN(pA)) {
		this.evtNo = pA;
		this.prizeRank = pB;
	} else {
		this.prizeRank = pA;
	}
}

function joinEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
	
    var evtValue = {
        path : "/public/promotion/event/joinEvent.json",
        params : new paramSet(evtNo, gfTurn)
    };
    eventCallAjax(evtValue);
}

var checkFlag=true;
var rouletteFlag = false;

function fn_joinRandomEvent(eventType){
	var evtEndYn = $("#eventEndYn").val();
	if(rouletteFlag){
		return;
	}

	if(evtEndYn == "Y"){
		alert("이벤트 기간이 종료되었습니다.");
		return;
	}

	if(checkFlag){
		checkFlag = false;

		var evtNo = $("#evtNo").val();
		$.ajax({
			type : "post",
			dataType : "json",
			url : '/public/promotion/event/rouletteEvtJoin.json',
			data:{"evtNo" : evtNo},
			success:function(jsonData){
				var resultCode = jsonData.resultCode;
				var resultPrizeRank = jsonData.result.evtFreeGiftInfo.prizeRank;
				var evtStatCd = jsonData.result.evt.evtStatCd;

				if(evtStatCd == 'STPGE'){
					commonAlert("해당 이벤트는 더이상 참여가 불가능합니다.");
                    checkFlag = true;
                }else{
					if(resultCode == "SUCCESS"){
						if(eventType == "roulette"){
							rouletteFlag = true;
							startSpin(resultPrizeRank);
						} else {
							startBox(resultPrizeRank);
						}
					}else if(resultCode == "NOT_LOGIN"){
						commonAlert2("로그인 시 참여 가능합니다.", fn_goLogin);
						return;
					}else if(resultCode == "INVALID_MEMBER"){
						commonConfirm2("멤버십 회원만 참여가능합니다.<br>멤버십 전환 페이지로 이동하시겠습니까?", function(){
							location.href = "/secured/mypage/certifyMember";
						});
					}else if(resultCode == "NO_MORE_CHANCE"){
						commonAlert("1일 1회 참여가능합니다.");
					}else if(resultCode == "EVT_STDR_1BT"){
						commonAlert("이미 이벤트에 참여 하였습니다.");
					}else if(resultCode == "WEEK_1_BT"){
						commonAlert("한주에 한번만 참여하실 수 있습니다.");
					}else if(resultCode == "NO_MORE_WINNER"){
						commonAlert("이벤트 경품이 소진 되었습니다.");
					}else if(resultCode == "INVALID_APPLCN_DATE"){
						commonAlert("이벤트 응모 기간이 아닙니다.");
					}else{
						commonAlert("이벤트 참여에 실패하였습니다.");
						location.reload();
					}
					checkFlag = true;
				}
			},
			error:function(xhr){
				var data = $.parseJSON(xhr.responseText);
				console.log(data.message);
				checkFlag = true;
			}
		});
	}
}

function startSpin(resultGiftTurn){
	// Ensure that spinning can't be clicked again while already running.
	if (wheelSpinning == false){
		// Based on the power level selected adjust the number of spins for the wheel, the more times is has
		// to rotate with the duration of the animation the quicker the wheel spins.
		if (wheelPower == 1){
			theWheel.animation.spins = 3;
		}else if (wheelPower == 2){
			theWheel.animation.spins = 8;
		}else if (wheelPower == 3){
			theWheel.animation.spins = 15;
		}

		// Begin the spin animation by calling startAnimation on the wheel object.
		endSegments = resultGiftTurn;
		theWheel.animation.stopAngle = theWheel.segments[endSegments].endAngle -45;
		theWheel.startAnimation();

		// Set to true so that power can't be changed and spin button re-enabled during
		// the current animation. The user will have to reset before spinning again.
		wheelSpinning = true;
	}
}

function joinEventMulti(evtNo) {
    var evtValue = {
        path : "/public/promotion/event/joinEventMulti.json",
        params : new paramSet(evtNo)
    };
    eventCallAjax(evtValue);
}

function eventCallAjax(dataValue) {
    
    if (dimCk) {
        dimCk = false;
    } else {
        return false;
    }

    var instantOptions = {};
    instantOptions.method = dataValue.method || "post";
    instantOptions.path = dataValue.path;
    instantOptions.data = dataValue.params;
    instantOptions.callbackSuccess = dataValue.callbackSuccess || defaultCallbackSuccess;
    instantOptions.callbackError = dataValue.callbackError || defaultCallbackError;

    if(dataValue.data && typeof dataValue.data != 'string'){
        instantOptions.data = $.param(dataValue.data);
    }
    
    $.ajax({
        type : instantOptions.method,
        async : true,
        url : instantOptions.path,
        data : instantOptions.data, 
        dataType : "json",
        //contentType : "application/json",
        beforeSend: function (request) {
            var csrfToken = $("meta[name='_csrf']").attr("content");
            var csrfName = $("meta[name='_csrf_header']").attr("content");
            request.setRequestHeader(csrfName, csrfToken);
        },
        success : function(result, status, jqXhr) {
            if (instantOptions.callbackSuccess) {
                instantOptions.callbackSuccess(result, status, jqXhr);
            }
        },
        error: function(jqXhr, status, error) {
            if (jqXhr.status == '403') {
                return;
            } else if (instantOptions.callbackError) {
                instantOptions.callbackError(jqXhr, status, error);
            }
        }
    }).done(function () {
        dimCk = true;
    });
    
    function defaultCallbackSuccess(result, status, jqXhr) {
        if (result.resultCode == 'SUCCESS') {
            alert(result.resultMessage);
        } else {
            if (result.resultCode == 'INVALID_MEMBER' || result.resultCode == 'UNITY_MBR_ONLY') {
				if (confirm("멥버십 회원만 참여 가능 합니다.\n멤버십 전환 페이지로 이동 하시겠습니까?") == true){
					location.href="/secured/mypage/certifyMember";
				}
				return;
            } else {
				alert(result.resultMessage);
			}
        }
        if (result.resultUrl) {
            location.href = result.resultUrl;
        }
    }
    
    function defaultCallbackError(jqXhr, status, error) {
        if (jqXhr.responseText) {
            var data = $.parseJSON(jqXhr.responseText)
            alert(data.message);
        }
    }
}

// 보물찾기 EVENT
function joinStamp2(dataValue) {
	try {
		showLoading("");
	} catch(e) {}
    $.ajax({
        type 	 : "POST",
        dataType : "json",
        async	 : true,
        url		 : '/public/singleEvent/treasureHunt.json',
        data	 : dataValue.data,
        beforeSend: function (request) {
            var csrfToken = $("meta[name='_csrf']").attr("content");
            var csrfName = $("meta[name='_csrf_header']").attr("content");
            request.setRequestHeader(csrfName, csrfToken);
        },
        success  : function(data) {
            if(data) {
                alert(data.result.rtnMsg);
            }
            hideLoading();
        },
        error: function( xhr, status, err ) {
            var data = $.parseJSON(xhr.responseText)
            alert(data.message);
            hideLoading();
        }
    });
}

function fn_goRecptnInfo(){
	location.href="/secured/mypage/updateMember";
}

function fn_goRecptnLogin(){
	location.href="/public/member/login";
}

function fn_goRecptnReload(){
	location.reload();
}

//수신동의 이벤트 참여 성공
function recptnCallbackSuccess(result, recptnType) {
	var resultMessage;
	
	if (recptnType == "EMAIL") {
		resultMessage = "이메일 수신동의 후 참여 가능합니다.</br>수신동의 하러 가시겠습니까?";
	} else if (recptnType == "SMS") {
		resultMessage = "SMS 수신동의 후 참여 가능합니다.</br>수신동의 하러 가시겠습니까?";
	} else if (recptnType == "SMS_EMAIL") {
		resultMessage = "SMS, 이메일 모두 수신동의 후 참여 가능합니다.</br>수신동의 하러 가시겠습니까?";
	} else if (recptnType == "APP_ORDER") {
		resultMessage = "이벤트 기간 내 APP에서 주문 결제 후,</br>참여 가능합니다.";
	} else if (recptnType == "2_ORDER") {
		resultMessage = "이벤트 기간 내 2회 이상 주문 결제 후,</br>참여 가능합니다.";
	}
	
 if(result) {
 	if (result.resultCode == 'SUCCESS') {
 		commonAlert(result.resultMessage);
     } else if (result.resultCode == "NOT_PARTCPTN_TGT") {
     	if (recptnType == "EMAIL" || recptnType == "SMS" || recptnType == "SMS_EMAIL") {
     		commonConfirm2(resultMessage, fn_goRecptnInfo)
     	} else if (recptnType == "APP_ORDER" || recptnType == "2_ORDER") {
     		commonAlert(resultMessage);
     	}
     } else if (result.resultCode == "NOT_LOGIN") {
    	commonAlert2("로그인 시 참여 가능합니다.",fn_goRecptnLogin);
     } else {
         if (result.resultMessage) {
        	 commonAlert(result.resultMessage);
         }
     }
 }
}


//수신동의 이벤트 참여 실패
function recptnCallbackError() {
	commonAlert2("이벤트 참여에 실패하였습니다.", fn_goRecptnReload);
}


//이메일 수신동의 확인 후 이벤트 참여
function joinEmailEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }

	$.ajax({
	     type 	 : "POST",
	     dataType : "json",
	     async	 : true,
	     url		 : '/public/promotion/event/joinEmailEvent.json',
	     data	 : new paramSet(evtNo, gfTurn),
	     beforeSend: function (request) {
	         var csrfToken = $("meta[name='_csrf']").attr("content");
	         var csrfName = $("meta[name='_csrf_header']").attr("content");
	         request.setRequestHeader(csrfName, csrfToken);
	     },
	     success  : function(data) {
	         if(data) {
	         	recptnCallbackSuccess(data, "EMAIL");
	         }
	     },
	     error: function( xhr, status, err ) {
	     	recptnCallbackError();
	     }
	}).done(function () {
	        dimCk = true;
	});
}


//SMS 수신동의 확인 후 이벤트 참여
function joinSmsEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }

	$.ajax({
	     type 	 : "POST",
	     dataType : "json",
	     async	 : true,
	     url		 : '/public/promotion/event/joinSmsEvent.json',
	     data	 : new paramSet(evtNo, gfTurn),
	     beforeSend: function (request) {
	         var csrfToken = $("meta[name='_csrf']").attr("content");
	         var csrfName = $("meta[name='_csrf_header']").attr("content");
	         request.setRequestHeader(csrfName, csrfToken);
	     },
	     success  : function(data) {
	         if(data) {
	         	recptnCallbackSuccess(data, "SMS");
	         }
	     },
	     error: function( xhr, status, err ) {
	     	recptnCallbackError();
	     }
	 }).done(function () {
	        dimCk = true;
	 });
}


//SMS & Email 수신동의 확인 후 이벤트 참여
function joinSmsEmailEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }

	$.ajax({
	   type 	 : "POST",
	   dataType : "json",
	   async	 : true,
	   url		 : '/public/promotion/event/joinSmsEmailEvent.json',
	   data	 : new paramSet(evtNo, gfTurn),
	   beforeSend: function (request) {
	       var csrfToken = $("meta[name='_csrf']").attr("content");
	       var csrfName = $("meta[name='_csrf_header']").attr("content");
	       request.setRequestHeader(csrfName, csrfToken);
	   },
	   success  : function(data) {
	       if(data) {
	       	recptnCallbackSuccess(data, "SMS_EMAIL");
	       }
	   },
	   error: function( xhr, status, err ) {
	   	recptnCallbackError();
	   }
	}).done(function () {
        dimCk = true;
    });
}


//앱 구매 1회 확인 후 이벤트 참여
function joinAppOrderEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }

	$.ajax({
	     type 	 : "POST",
	     dataType : "json",
	     async	 : true,
	     url		 : '/public/promotion/event/joinAppOrderEvent.json',
	     data	 : new paramSet(evtNo, gfTurn),
	     beforeSend: function (request) {
	         var csrfToken = $("meta[name='_csrf']").attr("content");
	         var csrfName = $("meta[name='_csrf_header']").attr("content");
	         request.setRequestHeader(csrfName, csrfToken);
	     },
	     success  : function(data) {
	         if(data) {
	         	recptnCallbackSuccess(data, "APP_ORDER");
	         }
	     },
	     error: function( xhr, status, err ) {
	     	recptnCallbackError();
	     }
	}).done(function () {
	        dimCk = true;
	});
}


//2회 이상 구매 확인 후 이벤트 참여
function join2OrderEvent(evtNo, gfTurn) {
	if(evtNo == '' || evtNo == null || evtNo == undefined){
		commonAlert("이벤트 번호 확인 후 다시 시도해 주세요.");
		return;
	}
	
	if(gfTurn == '' || gfTurn == null || gfTurn == undefined){
		commonAlert("경품 등수 확인 후 다시 시도해 주세요.");
		return;
	}
    
    if (dimCk) {
    	dimCk = false;
    } else {
        return false;
    }

	$.ajax({
	     type 	 : "POST",
	     dataType : "json",
	     async	 : true,
	     url		 : '/public/promotion/event/join2OrderEvent.json',
	     data	 : new paramSet(evtNo, gfTurn),
	     beforeSend: function (request) {
	         var csrfToken = $("meta[name='_csrf']").attr("content");
	         var csrfName = $("meta[name='_csrf_header']").attr("content");
	         request.setRequestHeader(csrfName, csrfToken);
	     },
	     success  : function(data) {
	         if(data) {
	         	recptnCallbackSuccess(data, "2_ORDER");
	         }
	     },
	     error: function( xhr, status, err ) {
	     	recptnCallbackError();
	     }
	}).done(function () {
	        dimCk = true;
	});
}

/*	
 *  #116685 [20년 3분기][이벤트]상품리스트 내 찜 기능 추가 
 *	상품 상세 이동 방식 변경
*/
function fn_godDetail(brndNm, godNo, utag, evtNo, set, dpos) {
	var url = "/" + brndNm + "/" + godNo + "/good?&utag=" + utag + "ref_evt:event*" + evtNo  + "$set:" + set + "$dpos:" + dpos;
	location.href = url;
}
function fn_godDetail02(brndNm, godNo, evtNo, set, dpos) {
	var url = "/" + brndNm + "/" + godNo + "/good?&utag=" + parentsUtag + "ref_evt:event*" + evtNo  + "$set:" + set + "$dpos:" + dpos;
	location.href = url;
}

// 이벤트 응모자 모니터링 함수
function fn_eventApplyCnt(evtNo) {
	try {
		$.ajax({
			type: "post",
			url: "/public/promotion/event/viewVoteCnt.json",
			dataType: "json",
			data: { evtNo: evtNo },
			success: function (data) {
				var voteCntList = data.result;
				var freeGiftTurn = "";
				var voteCnt = "";
				for (var i = 0; i < voteCntList.length; i++) {
					voteCnt = voteCntList[i].voteCount;
					freeGiftTurn = voteCntList[i].freeGiftTurn;
					console.log(">>> " + evtNo + " [" + freeGiftTurn + "] 경품 응모자수 : " + voteCnt);
				}
			},
			error: function (request, status, error) {
				console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
			}
		});
	} catch (e) {
		console.log(e);
	}
}